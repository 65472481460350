import clsx from "clsx";
import {
    Alert,
    AlertSeverity,
    Box,
    BoxRadius,
    Button,
    ButtonSize,
    ButtonStyle,
    Checkbox,
    Color,
    FieldBlock,
    FlexContent,
    FlexContentAlignment,
    FlexContentDirection,
    FlexContentLayout,
    FlexContentMobileDirection,
    FlexContentSpacing,
    FormLayoutRows,
    FormLayoutSeparator,
    FormRowTitle,
    InputText,
    LayoutRows,
    SelectAutocomplete,
    Tag,
    TagSize,
    TagStyle,
    ToggleSwitch
} from "@sirdata/ui-lib";
import {FC, useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {CmpConfig} from "../../api/model/cmp/CmpConfig";
import {Container} from "../../api/model/container/Container";
import {ContainerErrorCode} from "../../api/model/container/ContainerErrorCode";
import {ContainerField} from "../../api/model/container/ContainerField";
import {ContainerProperties, DEFAULT_GTM_DATALAYER} from "../../api/model/container/ContainerProperties";
import {ContainerPropertiesField} from "../../api/model/container/ContainerPropertiesField";
import {detectChanges} from "../../common/utils/portal";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {getRandomPath, sirdataGtmServerSidePricingUrl} from "../../utils/helper";
import {HelperDomain} from "../../api/model/analytics-helper/HelperDomain";
import {Origin} from "../../common/api/model/Origin";
import {session} from "../../api/ApiSession";
import {TranslationPortalFile} from "../../utils/constants";
import {UIEventManager} from "../../common/utils/UIEventManager";
import {useDebounce} from "../../utils/hooks/useDebounce";
import {usePreprod} from "../../common/utils/helper";

type ContainerDetailsConfigurationAdvancedProps = {
    container: Container;
    onEdit: (container: Container) => void;
}

const ContainerDetailsConfigurationAdvanced: FC<ContainerDetailsConfigurationAdvancedProps> = ({container: initContainer, onEdit}) => {
    const {t: textContainer} = useTranslation(TranslationPortalFile.CONTAINER);

    const [cmpConfigList, setCmpConfigList] = useState<CmpConfig[]>([]);
    const [helperDomainList, setHelperDomainList] = useState<HelperDomain[]>([]);

    const [container, setContainer] = useState<Container>(initContainer);
    const debouncedContainer = useDebounce<Container>(container, 750);

    const [dataLayerCustom, setDataLayerCustom] = useState<boolean>(!!initContainer.default_properties.gtm_datalayer);

    useEffect(() => {
        (async function () {
            try {
                setCmpConfigList(await session.restCmp.list());
                setHelperDomainList((await session.restHelperDomain.search()).elements);
            } catch (e) {}
        })();
    }, []);

    useEffect(() => {
        setContainer(initContainer);
        setDataLayerCustom(!!initContainer.default_properties.gtm_datalayer);
    }, [initContainer]);

    const updateContainer = useCallback(async () => {
        try {
            const updatedContainer = await session.restContainer.update(debouncedContainer);
            UIEventManager.alert({text: textContainer("success.update"), severity: AlertSeverity.SUCCESS, isClosable: true});
            onEdit(updatedContainer);
        } catch (e) {
            UIEventManager.alert({
                text: textContainer(`error.${e instanceof ErrorResponse ? e.code : ContainerErrorCode.FAIL_UPDATE_CONF}`),
                severity: AlertSeverity.DANGER,
                isClosable: true
            });
        }
    }, [textContainer, debouncedContainer, onEdit]);

    useEffect(() => {
        if (detectChanges(debouncedContainer.default_properties, initContainer.default_properties)) {
            updateContainer();
        }
    }, [debouncedContainer.default_properties, updateContainer, initContainer.default_properties]);

    const handleChangeDefaultProperties = (fields: {[field: string]: any}) => {
        const newContainer = new Container({...container, [ContainerField.DEFAULT_PROPERTIES]: new ContainerProperties({...container.default_properties, ...fields})});
        setContainer(newContainer);
    };

    const handleChangeDataLayerCustom = (checked: boolean) => {
        setDataLayerCustom(checked);
        if (!checked && container.default_properties.gtm_datalayer) {
            handleChangeDefaultProperties({[ContainerPropertiesField.GTM_DATALAYER]: ""});
        }
    };

    const formBlocked = !container.default_properties.processing_overlay;

    return (
        <LayoutRows>
            <FlexContent
                direction={FlexContentDirection.ROW}
                alignment={FlexContentAlignment.CENTER}
                spacing={FlexContentSpacing.SMALL}
                mobileDirection={FlexContentMobileDirection.COLUMN}
            >
                <ToggleSwitch
                    label={textContainer(`field.${ContainerField.DEFAULT_PROPERTIES}.${ContainerPropertiesField.PROCESSING_OVERLAY}`)}
                    name={ContainerPropertiesField.PROCESSING_OVERLAY}
                    checked={container.default_properties.processing_overlay}
                    onChange={(value) => handleChangeDefaultProperties({[ContainerPropertiesField.PROCESSING_OVERLAY]: value})}
                />
                <Tag
                    label={textContainer(`tag.${ContainerPropertiesField.PROCESSING_OVERLAY}`)}
                    style={TagStyle.DEFAULT_CYAN_LIGHT}
                    size={TagSize.SMALL}
                    icon={{name: "help", colorIcon: Color.CYAN, onClick: () => window.open(sirdataGtmServerSidePricingUrl, "_blank")}}
                    reverse
                />
            </FlexContent>
            <FlexContent
                direction={FlexContentDirection.ROW}
                alignment={FlexContentAlignment.START}
                layout={FlexContentLayout.COLUMNS}
                spacing={FlexContentSpacing.MEDIUM}
                mobileDirection={FlexContentMobileDirection.COLUMN}
                cssClass={clsx("container-details-advanced-configuration", {"container-details-advanced-configuration--blocked": formBlocked})}
            >
                <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.MEDIUM}>
                    <Box radius={BoxRadius.MD} cssClass="container-details-advanced-configuration__box">
                        <FormLayoutRows>
                            <FormRowTitle>
                                <Tag label={textContainer("advanced_configuration.ad_blockers")} style={TagStyle.DEFAULT_OCEAN}/>
                            </FormRowTitle>
                            <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                                <ToggleSwitch
                                    label={textContainer(`field.${ContainerField.DEFAULT_PROPERTIES}.${ContainerPropertiesField.GTM_PATH_ACTIVE}`)}
                                    name={ContainerPropertiesField.GTM_PATH_ACTIVE}
                                    checked={container.default_properties.gtm_path_active}
                                    onChange={(checked) => {
                                        handleChangeDefaultProperties({
                                            [ContainerPropertiesField.GTM_PATH_ACTIVE]: checked,
                                            [ContainerPropertiesField.GTM_PATH]: container.default_properties.gtm_path || `${getRandomPath()}.js`
                                        });
                                    }}
                                />
                                <Alert text={textContainer(`alert.${ContainerPropertiesField.GTM_PATH_ACTIVE}`)}/>
                            </FormLayoutRows>
                            <Box radius={BoxRadius.MD} cssClass={clsx("sub-configuration-box", {"sub-configuration-box--blocked": !container.default_properties.gtm_path_active})}>
                                <FormLayoutRows>
                                    <FieldBlock
                                        name={textContainer(`field.${ContainerField.DEFAULT_PROPERTIES}.${ContainerPropertiesField.GTM_ID}`)}
                                        label={textContainer(`field.${ContainerField.DEFAULT_PROPERTIES}.${ContainerPropertiesField.GTM_ID}`)}
                                    >
                                        <InputText
                                            placeholder={textContainer(`placeholder.${ContainerPropertiesField.GTM_ID}`)}
                                            value={container.default_properties.gtm_id}
                                            onChange={(value) => handleChangeDefaultProperties({[ContainerPropertiesField.GTM_ID]: value})}
                                            disabled={!container.default_properties.gtm_path}
                                        />
                                    </FieldBlock>
                                    <Checkbox
                                        label={textContainer(`label.${ContainerPropertiesField.GTM_DATALAYER}`)}
                                        name={ContainerPropertiesField.GTM_DATALAYER}
                                        checked={dataLayerCustom}
                                        onChange={handleChangeDataLayerCustom}
                                        disabled={!container.default_properties.gtm_path}
                                    />
                                    {dataLayerCustom &&
                                        <FieldBlock
                                            name={textContainer(`field.${ContainerField.DEFAULT_PROPERTIES}.${ContainerPropertiesField.GTM_DATALAYER}`)}
                                            label={textContainer(`field.${ContainerField.DEFAULT_PROPERTIES}.${ContainerPropertiesField.GTM_DATALAYER}`)}
                                        >
                                            <InputText
                                                placeholder={textContainer(`placeholder.${ContainerPropertiesField.GTM_DATALAYER}`)}
                                                value={container.default_properties.gtm_datalayer === DEFAULT_GTM_DATALAYER ? "" : container.default_properties.gtm_datalayer}
                                                onChange={(value) => handleChangeDefaultProperties({[ContainerPropertiesField.GTM_DATALAYER]: value})}
                                                disabled={!container.default_properties.gtm_path}
                                            />
                                        </FieldBlock>
                                    }
                                    <FormLayoutSeparator/>
                                    <FormRowTitle>
                                        <Tag label={textContainer("tag.experimental")} customColor={{color: Color.WARNING_MAIN, backgroundColor: Color.WARNING_LIGHTER, borderColor: Color.WARNING_LIGHT}}/>
                                    </FormRowTitle>
                                    <FormLayoutRows spacing={FlexContentSpacing.XSMALL}>
                                        <FieldBlock
                                            label={textContainer(`field.${ContainerField.DEFAULT_PROPERTIES}.${ContainerPropertiesField.GA4_PATH_ACTIVE}`)}
                                        >
                                            <ToggleSwitch
                                                label={textContainer(`label.${ContainerPropertiesField.GA4_PATH_ACTIVE}`)}
                                                name={ContainerPropertiesField.GA4_PATH_ACTIVE}
                                                checked={container.default_properties.ga4_path_active}
                                                onChange={(checked) => {
                                                    handleChangeDefaultProperties({
                                                        [ContainerPropertiesField.GA4_PATH_ACTIVE]: checked,
                                                        [ContainerPropertiesField.GA4_PATH]: container.default_properties.ga4_path || `${getRandomPath()}.js`,
                                                        [ContainerPropertiesField.GA4_COLLECT_PATH]: container.default_properties.ga4_collect_path || getRandomPath()
                                                    });
                                                }}
                                            />
                                        </FieldBlock>
                                        <Alert text={textContainer(`alert.${ContainerPropertiesField.GA4_PATH_ACTIVE}`)}/>
                                    </FormLayoutRows>
                                </FormLayoutRows>
                            </Box>
                        </FormLayoutRows>
                    </Box>
                    <Box radius={BoxRadius.MD} cssClass="container-details-advanced-configuration__box">
                        <FormLayoutRows>
                            <FormRowTitle>
                                <Tag label={textContainer("advanced_configuration.enrichment")} style={TagStyle.DEFAULT_OCEAN}/>
                            </FormRowTitle>
                            <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                                <ToggleSwitch
                                    label={textContainer(`field.${ContainerField.DEFAULT_PROPERTIES}.${ContainerPropertiesField.COOKIELESS_ID_1P}`)}
                                    name={ContainerPropertiesField.COOKIELESS_ID_1P}
                                    checked={container.default_properties.cookieless_id_1p}
                                    onChange={(value) => handleChangeDefaultProperties({[ContainerPropertiesField.COOKIELESS_ID_1P]: value})}
                                />
                                <Alert text={textContainer(`alert.${ContainerPropertiesField.COOKIELESS_ID_1P}`)}/>
                            </FormLayoutRows>
                            <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                                <ToggleSwitch
                                    label={textContainer(`field.${ContainerField.DEFAULT_PROPERTIES}.${ContainerPropertiesField.COOKIELESS_ID_3P}`)}
                                    name={ContainerPropertiesField.COOKIELESS_ID_3P}
                                    checked={container.default_properties.cookieless_id_3p}
                                    onChange={(value) => handleChangeDefaultProperties({[ContainerPropertiesField.COOKIELESS_ID_3P]: value})}
                                />
                                <Alert text={textContainer(`alert.${ContainerPropertiesField.COOKIELESS_ID_3P}`)}/>
                            </FormLayoutRows>
                            <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                                <ToggleSwitch
                                    label={textContainer(`field.${ContainerField.DEFAULT_PROPERTIES}.${ContainerPropertiesField.FIRSTID_ACTIVE}`)}
                                    name={ContainerPropertiesField.FIRSTID_ACTIVE}
                                    checked={container.default_properties.firstid_active}
                                    onChange={(value) => handleChangeDefaultProperties({[ContainerPropertiesField.FIRSTID_ACTIVE]: value})}
                                />
                                <Alert text={textContainer(`alert.${ContainerPropertiesField.FIRSTID_ACTIVE}`)}/>
                                <Box radius={BoxRadius.MD} cssClass={clsx("sub-configuration-box", {"sub-configuration-box--blocked": !container.default_properties.firstid_active})}>
                                    <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                                        <ToggleSwitch
                                            label={textContainer(`field.${ContainerField.DEFAULT_PROPERTIES}.${ContainerPropertiesField.FIRSTID_AUTOLOAD}`)}
                                            name={ContainerPropertiesField.FIRSTID_AUTOLOAD}
                                            checked={container.default_properties.firstid_autoload}
                                            onChange={(value) => handleChangeDefaultProperties({[ContainerPropertiesField.FIRSTID_AUTOLOAD]: value})}
                                        />
                                        <Alert text={textContainer(`alert.${ContainerPropertiesField.FIRSTID_AUTOLOAD}`)}/>
                                    </FormLayoutRows>
                                </Box>
                            </FormLayoutRows>
                            <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                                <ToggleSwitch
                                    label={textContainer(`field.${ContainerField.DEFAULT_PROPERTIES}.${ContainerPropertiesField.HASHED_MAIL_ENRICH_ACTIVE}`)}
                                    name={ContainerPropertiesField.HASHED_MAIL_ENRICH_ACTIVE}
                                    checked={container.default_properties.hashed_mail_enrich_active}
                                    onChange={(value) => handleChangeDefaultProperties({[ContainerPropertiesField.HASHED_MAIL_ENRICH_ACTIVE]: value})}
                                />
                                <Alert text={textContainer(`alert.${ContainerPropertiesField.HASHED_MAIL_ENRICH_ACTIVE}`)}/>
                                <Box radius={BoxRadius.MD} cssClass={clsx("sub-configuration-box", {"sub-configuration-box--blocked": !container.default_properties.hashed_mail_enrich_active})}>
                                    <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                                        <ToggleSwitch
                                            label={textContainer(`field.${ContainerField.DEFAULT_PROPERTIES}.${ContainerPropertiesField.HASHED_MAIL_SHARING_ACTIVE}`)}
                                            name={ContainerPropertiesField.HASHED_MAIL_SHARING_ACTIVE}
                                            checked={container.default_properties.hashed_mail_sharing_active}
                                            onChange={(value) => handleChangeDefaultProperties({[ContainerPropertiesField.HASHED_MAIL_SHARING_ACTIVE]: value})}
                                        />
                                        <Alert text={textContainer(`alert.${ContainerPropertiesField.HASHED_MAIL_SHARING_ACTIVE}`)}/>
                                        <ToggleSwitch
                                            label={textContainer(`field.${ContainerField.DEFAULT_PROPERTIES}.${ContainerPropertiesField.HASHED_MAIL_AUTO_GA4_ACTIVE}`)}
                                            name={ContainerPropertiesField.HASHED_MAIL_AUTO_GA4_ACTIVE}
                                            checked={container.default_properties.hashed_mail_auto_ga4_active}
                                            onChange={(value) => handleChangeDefaultProperties({[ContainerPropertiesField.HASHED_MAIL_AUTO_GA4_ACTIVE]: value})}
                                        />
                                        <Alert text={textContainer(`alert.${ContainerPropertiesField.HASHED_MAIL_AUTO_GA4_ACTIVE}`)}/>
                                    </FormLayoutRows>
                                </Box>
                            </FormLayoutRows>
                        </FormLayoutRows>
                    </Box>
                </FlexContent>
                <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.MEDIUM}>
                    <Box radius={BoxRadius.MD} cssClass="container-details-advanced-configuration__box">
                        <FormLayoutRows>
                            <FormRowTitle>
                                <Tag label={textContainer("advanced_configuration.script_optimization")} style={TagStyle.DEFAULT_OCEAN}/>
                            </FormRowTitle>
                            <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                                <ToggleSwitch
                                    label={textContainer(`field.${ContainerField.DEFAULT_PROPERTIES}.${ContainerPropertiesField.CMP_EMBED}`)}
                                    name={ContainerPropertiesField.CMP_EMBED}
                                    checked={container.default_properties.cmp_embed}
                                    onChange={(value) => handleChangeDefaultProperties({[ContainerPropertiesField.CMP_EMBED]: value})}
                                />
                                <Box radius={BoxRadius.MD} cssClass={clsx("sub-configuration-box", {"sub-configuration-box--blocked": !container.default_properties.cmp_embed})}>
                                    <FieldBlock
                                        name={textContainer(`field.${ContainerField.DEFAULT_PROPERTIES}.${ContainerPropertiesField.CMP_CONFIG_ID}`)}
                                        label={textContainer(`field.${ContainerField.DEFAULT_PROPERTIES}.${ContainerPropertiesField.CMP_CONFIG_ID}`)}
                                    >
                                        <SelectAutocomplete
                                            value={container.default_properties.cmp_config_id}
                                            placeholder={textContainer(`placeholder.${ContainerPropertiesField.CMP_CONFIG_ID}`)}
                                            options={cmpConfigList.map(({id, id_partner, name}) => ({label: `${id} (${name})`, value: id, id_partner}))}
                                            onChange={(opt) => handleChangeDefaultProperties({
                                                [ContainerPropertiesField.CMP_CONFIG_ID]: opt?.value || "",
                                                [ContainerPropertiesField.CMP_PARTNER_ID]: opt?.id_partner || null
                                            })}
                                            disabled={!container.default_properties.cmp_embed}
                                            clearable
                                        />
                                        <Button
                                            style={ButtonStyle.PRIMARY_MIDNIGHT}
                                            size={ButtonSize.SMALL}
                                            disabled={!container.default_properties.cmp_embed}
                                            onClick={() => window.open(`${Origin.CMP.getUrl(usePreprod)}/cmp`, "_blank")}
                                        >
                                            {textContainer("action.create_config")}
                                        </Button>
                                    </FieldBlock>
                                </Box>
                            </FormLayoutRows>
                            <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                                <ToggleSwitch
                                    label={textContainer(`field.${ContainerField.DEFAULT_PROPERTIES}.${ContainerPropertiesField.GTM_ORDER_DATALAYER}`)}
                                    name={ContainerPropertiesField.GTM_ORDER_DATALAYER}
                                    checked={container.default_properties.gtm_order_datalayer}
                                    onChange={(value) => handleChangeDefaultProperties({[ContainerPropertiesField.GTM_ORDER_DATALAYER]: value})}
                                />
                                <Alert text={textContainer(`alert.${ContainerPropertiesField.GTM_ORDER_DATALAYER}`)}/>
                            </FormLayoutRows>
                        </FormLayoutRows>
                    </Box>
                    <Box radius={BoxRadius.MD} cssClass="container-details-advanced-configuration__box">
                        <FormLayoutRows>
                            <FormRowTitle>
                                <Tag label={textContainer("advanced_configuration.transparency")} style={TagStyle.DEFAULT_OCEAN}/>
                            </FormRowTitle>
                            <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                                <ToggleSwitch
                                    label={textContainer(`field.${ContainerField.DEFAULT_PROPERTIES}.${ContainerPropertiesField.APPLY_TRANSPARENCY}`)}
                                    name={ContainerPropertiesField.APPLY_TRANSPARENCY}
                                    checked={container.default_properties.apply_transparency}
                                    onChange={(value) => handleChangeDefaultProperties({[ContainerPropertiesField.APPLY_TRANSPARENCY]: value})}
                                />
                                <Alert text={textContainer(`alert.${ContainerPropertiesField.APPLY_TRANSPARENCY}`)}/>
                            </FormLayoutRows>
                            <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                                <ToggleSwitch
                                    label={textContainer(`field.${ContainerField.DEFAULT_PROPERTIES}.${ContainerPropertiesField.AVOID_COOKIES}`)}
                                    name={ContainerPropertiesField.AVOID_COOKIES}
                                    checked={container.default_properties.avoid_cookies}
                                    onChange={(value) => handleChangeDefaultProperties({[ContainerPropertiesField.AVOID_COOKIES]: value})}
                                />
                                <Alert text={textContainer(`alert.${ContainerPropertiesField.AVOID_COOKIES}`)}/>
                            </FormLayoutRows>
                            <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                                <ToggleSwitch
                                    label={textContainer(`field.${ContainerField.DEFAULT_PROPERTIES}.${ContainerPropertiesField.HELPER_ACTIVE}`)}
                                    name={ContainerPropertiesField.HELPER_ACTIVE}
                                    checked={container.default_properties.helper_active}
                                    onChange={(value) => handleChangeDefaultProperties({[ContainerPropertiesField.HELPER_ACTIVE]: value})}
                                />
                                <Box radius={BoxRadius.MD} cssClass={clsx("sub-configuration-box", {"sub-configuration-box--blocked": !container.default_properties.helper_active})}>
                                    <FieldBlock
                                        name={textContainer(`field.${ContainerField.DEFAULT_PROPERTIES}.${ContainerPropertiesField.HELPER_CONFIG_ID}`)}
                                        label={textContainer(`field.${ContainerField.DEFAULT_PROPERTIES}.${ContainerPropertiesField.HELPER_CONFIG_ID}`)}
                                    >
                                        <SelectAutocomplete
                                            value={container.default_properties.helper_config_id || undefined}
                                            placeholder={textContainer(`placeholder.${ContainerPropertiesField.HELPER_CONFIG_ID}`)}
                                            options={helperDomainList.map(({id, name, partner_id}) => ({label: `${id} (${name})`, value: id, partner_id}))}
                                            onChange={(opt) => handleChangeDefaultProperties({
                                                [ContainerPropertiesField.HELPER_CONFIG_ID]: opt?.value || null,
                                                [ContainerPropertiesField.HELPER_PARTNER_ID]: opt?.partner_id || null
                                            })}
                                            disabled={!container.default_properties.helper_active}
                                            clearable
                                        />
                                        <Button
                                            style={ButtonStyle.PRIMARY_MIDNIGHT}
                                            size={ButtonSize.SMALL}
                                            disabled={!container.default_properties.helper_active}
                                            onClick={() => window.open(`${Origin.HELPER.getUrl(usePreprod)}/sites`, "_blank")}
                                        >
                                            {textContainer("action.create_config")}
                                        </Button>
                                    </FieldBlock>
                                </Box>
                            </FormLayoutRows>
                        </FormLayoutRows>
                    </Box>
                </FlexContent>
            </FlexContent>
        </LayoutRows>
    );
};

export default ContainerDetailsConfigurationAdvanced;
